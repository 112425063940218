<template>
    <div id="ekle">
        <b-container>
            <b-row>
                <b-col sm="12">
                   <h1 class="mt-3 mb-4">{{firma.firma_unvan}}
                       <small>{{firma.firma_adres}}</small>
                   </h1>
                    <b-form @submit.prevent="ekle" class="text-left mt-1">
                        <b-form-group v-if="atik_turleri_select" label="Atık Türü Seçin:">
                            <b-form-select v-model="atik_turu" required :options="atik_turleri_select"></b-form-select>
                        </b-form-group>

                        <b-form-group
                            id="input-group-1"
                            label="Miktar:"
                            label-for="input-1">
                            <b-form-input
                            id="input-1"
                            v-model="miktar"
                            step="any"
                            required
                            type="number"
                            placeholder="Atık miktarını girin."
                            ></b-form-input>
                        </b-form-group>
                        <b-button block type="submit" variant="primary" class="float-right">Ekle</b-button>
                    </b-form>
                </b-col>
                <b-col sm="12">
                    <yandex-map 
                    :settings="settings" 
                    :coords="coords" 
                    :zoom="16" 
                    @click="harita_tiklandi"
                    class="mt-5 mb-5"
                    style="width: 100%; height: 400px;">
                        <ymap-marker :coords="coords" marker-id="12"></ymap-marker>
                    </yandex-map>
                </b-col>
            </b-row>
        </b-container>

        
    </div>
</template>

<script>
import { yandexMap, ymapMarker } from 'vue-yandex-maps'
import Swal from 'sweetalert2'

export default {
    data(){
        return{
            miktar : null,
            settings : {
                lang: 'tr_TR',
            },
            firma_id : this.$route.params.firma_id,
            atik_turu : null,
            //coords : [],
        }
    },

    components: { yandexMap, ymapMarker },

    computed : {
        konum(){
            return this.$store.state.konum;
        },
        user(){
            return this.$store.state.user;
        },
        firmalar(){
            return this.$store.state.firmalar;
        },
        atik_turleri(){
            return this.$store.state.atik_turleri;
        },
        atik_turleri_select(){
            return this.atik_turleri.map(c => {
                return {
                    text : c.atik_ad,
                    value : c.atik_id
                }
            });
        },
        firma(){
            return this.firmalar.filter(c => c.firma_id == this.$route.params.firma_id)[0];
        },
        coords(){
            return this.firma.firma_konum.split(",");
        }
    },

    mounted(){
        //this.coords = this.firma.firma_konum.split(",");
        navigator.geolocation.getCurrentPosition(pos => {
            this.$store.dispatch("konum", [pos.coords.latitude, pos.coords.longitude] )
        })  
    },

    methods : {
        ekle(){
            var popup = Swal.fire({
                position: 'center',
                icon: 'warning',
                title: 'Lütfen Bekleyin',
                showConfirmButton: false,
            });
            this.$store.dispatch("atikEkle", {
                atik_alim_user : this.user.user_id,
                atik_alim_konum : this.coords[0] + "," + this.coords[1],
                atik_alim_firma : this.$route.params.firma_id,
                atik_alim_miktar : this.miktar,
                atik_alim_tur : this.atik_turu,
                atik_alim_durum : 2,
            })
            .then(r => {
                popup.close();
                if(r.status){
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Tebrikler',
                        text: 'İşleminiz başarıyla gerçekleştirildi.',
                        showConfirmButton: true,
                        confirmButtonText: 'Tamam'
                    }).then(() => {
                        this.$store.dispatch("rapor_home_firma");
                        this.$router.push("/")
                    });
                }
            });            
        },
        harita_tiklandi(e){
            this.coords = e.get('coords');
        },
    },
    watch : {

    }
}
</script>

<style scoped>
.card{
  border-radius: 0;
}
h1{
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
}
h1 small {
    display: block;
    font-size: 12px;
}
</style>